<template>
  <div class="project-details">
    <div class="Equibirium">
      <div class="left-Equibirium">
        <div class="nickname">
          <img :src="dataobj.logo" alt="" @error="imageLoad(dataobj.logo)" />
          <div>
            <p class="title">{{ dataobj.project }}</p>
            <p class="href">
              {{ $t("website") }}:
              <a :href="dataobj.website" target="_blank">{{
                dataobj.website
              }}</a>
            </p>
          </div>
        </div>
        <div class="Threats">
          <div
            v-for="item in dataobj.community"
            :key="item.id"
            @click="openBox(item.url)"
          >
            <img
              :src="item.logo ? item.logo : '@/static/images/18.png'"
              alt=""
              srcset=""
            />
            {{ item.name }}
          </div>
          <div class="social">
            <a href="#" @click="fookbookShare">
              <svg-icon iconClass="Facebook" className="icon"></svg-icon>
            </a>
            <a href="#" @click="twitterShare">
              <svg-icon iconClass="Twitter" className="icon"></svg-icon>
            </a>
            <a href="#" @click="copyshare">
              <svg-icon iconClass="copy" className="icon"></svg-icon>
            </a>
          </div>
        </div>
      </div>
      <div class="right-Equibirium">
        <div>
          <p class="title">{{ $t("AMOUNT") }}</p>
          <p class="money">${{ dataobj.amount }}</p>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="Description">
      <div class="left-Description">
        <div>
          <p class="describe">{{ $t("DESCRIPTION") }}</p>
          <p class="details">
            {{ dataobj.des }}
          </p>
        </div>
      </div>
      <div class="right-Description">
        <div>
          <p class="describe">{{ $t("ANNOUNCEMENT") }}</p>
          <p class="details">
            <a
              :href="dataobj.announcement"
              target="_blank"
              rel="noopener noreferrer"
              >{{ dataobj.announcement }}</a
            >
          </p>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="Investors">
      <div class="left-Investors">
        <div class="Hypersphere">
          <p class="invest">{{ $t("INVESTORS") }}</p>
          <div class="company">
            <p v-for="item in dataobj.investors" :key="item.id">{{ item }}</p>
          </div>
        </div>
        <div class="Regular" v-if="!CanIlikeit">
          <div class="left-Regular">
            <p class="title">{{ $t("vote") }}</p>
            <p class="describe">
              {{ $t("tomorrow", { project: dataobj.project }) }}
            </p>
          </div>
          <div class="right-Regular">
            <div class="tup">
              {{ Math.round(votedata.approve) }}%
              <img src="@/static/images/6.png" alt="" srcset="" /> Good
            </div>
            <el-progress
              :percentage="votedata.percentage"
              :show-text="false"
              color="#1EDFA6"
            ></el-progress>
            <div class="tup inferior">
              {{ Math.round(votedata.oppose) }}%
              <img src="@/static/images/7.png" alt="" srcset="" /> Bad
            </div>
          </div>
        </div>
        <div class="Regular blank" v-else>
          <div>
            <p style="font-size: 18px; color: #f0f0f0">
              {{ $t("givealike.title", { project: dataobj.project }) }}
            </p>
            <p style="font-size: 18px; color: #7a8087; margin-top: 10px">
              {{ $t("givealike.sontitle") }}
            </p>
          </div>
          <div class="button-box">
            <div class="" @click="approveOf">
              <img src="../../static/images/6.png" alt="" srcset="" /> Good
            </div>
            <div class="" @click="projectbad">
              <img src="../../static/images/7.png" alt="" srcset="" />Bad
            </div>
          </div>
        </div>
      </div>
      <div class="right-Investors">
        <p class="Other">{{ $t("OTHER") }}</p>
        <p class="Stackswap">
          DATE: <span>{{ dataobj.joinDate }}</span>
        </p>
        <p class="Stackswap" v-if="dataobj.founder">
          FOUNDER: <span>{{ dataobj.founder }}</span>
        </p>
        <p class="Stackswap" v-if="dataobj.category">
          SUB-CATEGORIES: <span>{{ dataobj.category }}</span>
        </p>
        <p class="Stackswap" v-if="dataobj.round">
          ROUND: <span>{{ dataobj.round }}</span>
        </p>
        <p class="Stackswap" v-if="dataobj.valuation">
          VALUATION: <span>${{ dataobj.valuation }}</span>
        </p>
        <div class="alignment">
          <img src="../../static/images/time.png" alt="" srcset="" />
          <p class="">
            {{
              $i18n.locale == "en" ? dataobj.enchangeTime : dataobj.changeTime
            }}
          </p>
        </div>
      </div>
    </div>

    <FaceBook
      v-if="dialogTableVisible"
      :dataobj="dataobj"
      @clearfacebook="clearfacebook"
    ></FaceBook>
  </div>
</template>

<script>
import FaceBook from "@/components/facebook.vue";
import {
  projectdetails,
  projectvote,
  projectgood,
  projectbad,
  projectcanVote,
} from "@/api/require.js";
import { getSocialWebsiteLogo } from "@/api/controller.js";
import { openUrl } from "@/utils";
export default {
  components: {
    FaceBook,
  },
  data() {
    return {
      dialogTableVisible: false,
      openUrlIF: false,
      dataobj: {},
      url: "",
      CanIlikeit: true,
      votedata: {
        approve: 0, //赞成
        oppose: 0, //反对
        percentage: 0, //百分比
      },
    };
  },
  created() {
    this.$tool.formatMonenyNumber(14515.23);

    // 接受到hrader广播
    this.Event.$on("data-refresh", (res) => {
      if (!res) {
        this.projectvote(this.$route.query.id);
      }
      this.projectcanVote(this.$route.query.id);
    });
    if (this.$route.query.id) {
      this.projectdetails(this.$route.query.id);
      // 查看是否可以投票
      this.projectcanVote(this.$route.query.id);
    }

    // 接受到广播
    this.Event.$on("search-project-details", (res) => {
      this.projectdetails(this.$route.query.id);
      this.projectcanVote(this.$route.query.id);
    });
  },
  methods: {
    imageLoad(src) {
      var Img = new Image();
      Img.src = src;
      if (Img.fileSize > 0 || (Img.width > 0 && Img.height > 0)) {
      } else {
        return (this.dataobj.logo = require("../../static/images/18.png"));
      }
    },

    async projectcanVote(id) {
      let res = await projectcanVote(id);
      if (res.code === 0) {
        if (res.data) {
          // 显示点赞
          this.CanIlikeit = true;
        } else {
          // 显示点过
          this.CanIlikeit = false;
          this.projectvote(this.$route.query.id);
        }
      } else {
        this.CanIlikeit = true;
      }
    },

    clearfacebook() {
      this.dialogTableVisible = false;
    },

    closeprogram() {
      this.openUrlIF = false;
    },

    openBox(url) {
      window.open(url);
      // if(url){
      //   this.url = url
      //   this.openUrlIF = true
      // }
    },

    async approveOf() {
      if (!this.Globaland()) {
        return false;
      }

      let res = await projectgood(this.$route.query.id);
      // console.log(res, '投赞成票')
      if (res.code === 0) {
        this.CanIlikeit = false;
        this.projectvote(this.$route.query.id);
      } else {
        this.$message.error(this.$t("error"));
      }
    },
    async projectbad() {
      if (!this.Globaland()) {
        return false;
      }

      let res = await projectbad(this.$route.query.id);
      // console.log(res, '投反对成票')
      if (res.code === 0) {
        this.CanIlikeit = false;
        this.projectvote(this.$route.query.id);
      } else {
        this.$message.error(this.$t("error"));
      }
    },

    Globaland() {
      try {
        if (!sessionStorage.getItem("token")) {
          // console.log('广播到index.vue')
          // 广播到index.vue
          this.Event.$emit("landing");
          return false;
        } else {
          return true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 投票信息
    async projectvote(id) {
      if (!this.Globaland()) {
        return false;
      }

      let res = await projectvote(id);
      if (res.code === 0) {
        // 关闭登陆框
        // 广播到index。vue
        this.Event.$emit("data-close");
        if (res.data.good > res.data.bad) {
          // 赞成大于反对
          let str1 = res.data.bad / (res.data.good + res.data.bad);
          let str2 = 1 - str1;
          this.votedata.approve = str2.toFixed(2) * 100;
          this.votedata.oppose = str1.toFixed(2) * 100;
          this.votedata.percentage = str2.toFixed(2) * 100;
        } else if (res.data.good == res.data.bad) {
          this.votedata.approve = 50;
          this.votedata.oppose = 50;
          this.votedata.percentage = 50;
        } else {
          let str1 = res.data.bad / (res.data.good + res.data.bad);
          let str2 = 1 - str1;
          this.votedata.approve = str2.toFixed(2) * 100;
          this.votedata.oppose = str1.toFixed(2) * 100;
          this.votedata.percentage = str2.toFixed(2) * 100;
        }
      }
      // 获取投票信息
      // console.log(res,'=====')
    },

    async projectdetails(id) {
      let res = await projectdetails(id);
      if (res.code === 0) {
        try {
          res.data.investors = res.data.investors.split(",");
        } catch (error) {}

        // 把时间格式转为英文
        // console.log('把时间格式转为英文');
        res.data["enchangeTime"] = this.$tool.conversion(res.data.changeTime);
        try {
          res.data.founder = res.data.founder.join(",");
        } catch (error) {
          console.log(error);
        }
        res.data.community.forEach(async (element) => {
          element["logo"] = await this.btn_logo(element.name);
          this.$forceUpdate();
        });
        // console.log('项目详情',res.data)
        res.data.amount = this.$tool.formatNumber(res.data.amount);
        this.dataobj = res.data;
      }
    },
    async btn_logo(site) {
      let res = await getSocialWebsiteLogo(site);
      if (res.code === 0) {
        if (res.data.indexOf("http") > -1) {
          return res.data;
        }
      }
    },
    fookbookShare() {
      const t = this.dataobj.project + this.dataobj.des;
      const u = window.location.href;
      openUrl(
        "http://www.facebook.com/sharer.php?u=" +
          encodeURIComponent(u) +
          "&text=" +
          encodeURIComponent(t),
        "sharer",
        "toolbar=yes,location=yes,directories=no,status=no,menuber=yes,scrollbars=yes,resizable=no,copyhistory=yes,width=600,height=450,top=100,left=350"
      );
    },
    twitterShare() {
      const twTitle = this.dataobj.project + this.dataobj.des;
      const twUrl = window.location.href;
      openUrl(
        "https://twitter.com/intent/tweet?url=" +
          encodeURIComponent(twUrl) +
          "&text=" +
          encodeURIComponent(twTitle),
        "_blank",
        "toolbar=yes,location=yes,directories=no,status=no,menuber=yes,scrollbars=yes,resizable=no,copyhistory=yes,width=600,height=450,top=100,left=350"
      );
    },

    async copyshare() {
      await this.$copyText(window.location.href);
      this.$notify.success(this.$t("copySuccess"));
    },

    Share() {
      this.dialogTableVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.project-details {
  .Investors {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .left-Investors {
      width: 50%;
      flex: auto;
      margin-right: 20px;
      border-radius: 20px;
      box-sizing: border-box;
      height: 416px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .Hypersphere {
        min-height: 248px;
        background: linear-gradient(0deg, #2d3138, #3a3f45);
        border-radius: 20px;
        padding: 40px;
        box-sizing: border-box;
        .invest {
          font-size: 18px;
          color: #fafafa;
          margin-bottom: 30px;
        }
        .company {
          &::-webkit-scrollbar {
            width: 2px;
            background: #fff;
            border-radius: 20px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #26e7ae;
          }
          display: flex;
          // align-items: center;
          flex-wrap: wrap;
          max-height: 134px;
          overflow: auto;
          cursor: pointer;
          p {
            border-radius: 20px;
            padding: 6px 15px;
            margin: 5px;
            height: 22px;
            // font-size: 10px;
            text-align: center;
            line-height: 22px;
            background: #3f444a;
          }
        }
      }
      .Regular {
        border-radius: 20px;
        overflow: hidden;
        background: linear-gradient(0deg, #2d3138, #3a3f45);
        height: 142px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px;
        box-sizing: border-box;
        .left-Regular {
          width: 48%;
          .describe {
            font-size: 10px;
            margin-top: 20px;
          }
          .title {
            font-size: 14px;
            // color: #96a4d1;
          }
        }
        /deep/.right-Regular {
          width: 48%;
          .el-progress {
            margin: 10px 0;
            .el-progress-bar__outer {
              height: 10px !important;
            }
            .el-progress-bar__outer {
              background: #1f5b72;
            }
          }
          .tup {
            font-size: 12px;
            color: #1edfa6;
            img {
              width: 12px;
              height: 12px;
              margin: 0 5px;
              cursor: pointer;
            }
          }
          .inferior {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
      .blank {
        .button-box {
          display: flex;
          align-items: center;
          div {
            cursor: pointer;
            border: 1px solid #1edfa6;
            border-radius: 5px;
            margin: 0 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 94px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            color: #1edfa6;
            img {
              margin-right: 12px;
            }
          }
        }
      }
    }
    .right-Investors {
      width: 50%;
      flex: auto;
      height: 416px;
      background: linear-gradient(0deg, #2d3138, #3a3f45);
      border-radius: 20px;
      padding: 40px;
      box-sizing: border-box;
      position: relative;
      .Other {
        font-size: 18px;
        color: #fafafa;
        margin-bottom: 30px;
      }
      .alignment {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 20px;
        right: 20px;
        font-size: 14px;
        color: #7b8087;
        img {
          width: 10px;
          height: 10px;
          margin-right: 10px;
        }
      }
      .Stackswap {
        line-height: 25px;
        // font-size: 14px;
        color: #7b8087;
        // span {
        //   color: #6c76a4;
        // }
      }
    }
  }

  .Description {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .left-Description {
      margin-right: 20px;
    }
    .left-Description,
    .right-Description {
      width: 50%;
      flex: auto;
      min-height: 140px;
      background: linear-gradient(0deg, #2d3138, #3a3f45);
      border-radius: 20px;
      display: flex;
      padding: 40px;
      box-sizing: border-box;
      word-break: break-word;
      .describe {
        font-size: 18px;
        color: #fafafa;
      }
      .details {
        margin-top: 20px;
        // font-size: 14px;
        // color: #96a4d1;
        line-height: 20px;
        a {
          color: #7b8087;
          text-decoration: none;

          &:hover {
            color: #1edfa6;
          }
        }
      }
    }
  }

  .Equibirium {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-Equibirium {
      flex: auto;
      height: 140px;
      background: linear-gradient(0deg, #2d3138, #3a3f45);
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px;
      box-sizing: border-box;
      .nickname {
        display: flex;
        align-items: center;
        word-break: break-word;
        flex: auto;
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
        div {
          margin-left: 20px;
          .title {
            font-size: 22px;
            color: #fafafa;
          }
          .href {
            letter-spacing: 0.5px;
            margin-top: 10px;
            cursor: pointer;
            font-size: 12px;
            // color: #96a4d1;
            a {
              color: #21e2a9;
            }
          }
        }
      }
      .Threats {
        display: flex;
        align-items: center;
        .social {
          // width: 200px;
          // height: 45px;
          border-radius: 40px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-around;
          font-size: 14px;
          color: #7b8087;
          text-align: center;
          // line-height: 25px;
          box-sizing: border-box;
        }

        a {
          text-decoration: none;
          margin: 5px;
          cursor: pointer;
          color: #7b8087;
          padding: 8px 20px;
          border-radius: 40px;
          background: #3f444a;

          .icon {
            margin: 0 2px;
            font-size: 20px;
          }
        }
      }
    }
    .right-Equibirium {
      // width: 357px;
      height: 140px;
      margin-left: 20px;
      background: linear-gradient(0deg, #2d3138, #3a3f45);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px 60px;
      box-sizing: border-box;
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #7b8087;
      }
      .money {
        margin-top: 10px;
        font-size: 50px;
        color: #f0f0f0;
      }
    }
  }
}
</style>
