<template>
  <div class="facebook">
    <el-dialog class="facebookel-dialog" title="" :visible.sync="dialogTableVisible" :show-close="false" :before-close="handleClose">
      <img
        class="gunabi"
        src="@/static/images/11.png"
        alt=""
        srcset=""
        @click="handleClose"
      />
      <div class="tup">
        <p>Share it with your friends</p>
        <img
          style="width: 168px; height: 110px"
          src="@/static/images/8.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="xiamian">
        <div class="fenxian">
          <img
            style="width: 40px; height: 40px"
            src="@/static/images/10.png"
            alt=""
            srcset=""
            @click="fookbackshare"
          />
          <img
            style="width: 40px; height: 40px"
            src="@/static/images/9.png"
            alt=""
            srcset=""
            @click="Twittershare"
          />
        </div>
      </div>
      <p class="copy-link">Or copy link</p>
      <el-input
        class="http"
        placeholder="https://www.foinimn.com"
        v-model="input1"
      >
        <div
          slot="append"
          v-clipboard:copy="input1"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          class="COPY"
        >
          COPY
        </div>
      </el-input>
    </el-dialog>
  </div>
</template>

<script>
export default {

    props:{
        dataobj:{
          type: Object,
          require: true
        }
    },
  data() {
    return {
      dialogTableVisible: true,
      input1: window.location.href,
    };
  },
  methods: {
    handleClose(){
      this.$emit('clearfacebook')
    },

    // 脸书分享
    fookbackshare() {
      var t = this.dataobj.project + this.dataobj.des;
      var u = this.input1;
      window.open(
        "http://www.facebook.com/sharer.php?u=" +
          encodeURIComponent(u) +
          "&text=" +
          encodeURIComponent(t),
        "sharer",
        "toolbar=yes,location=yes,directories=no,status=no,menuber=yes,scrollbars=yes,resizable=no,copyhistory=yes,width=600,height=450,top=100,left=350"
      );
    },

    // Twitter分享
    Twittershare() {
      var twTitle = this.dataobj.project + this.dataobj.des;
      var twUrl = this.input1;
      window.open(
        "https://twitter.com/intent/tweet?url=" +
          encodeURIComponent(twUrl) +
          "&text=" +
          encodeURIComponent(twTitle),
        "_blank",
        "toolbar=yes,location=yes,directories=no,status=no,menuber=yes,scrollbars=yes,resizable=no,copyhistory=yes,width=600,height=450,top=100,left=350"
      );
    },
    onCopy() {
      this.$message({
        message: this.$t('copySuccess'),
        type: "success",
      });
    },
    onError() {
      this.$message.error(this.$t('copyFail'));
    },
  },
};
</script>

<style lang="less" scoped>
.facebook {
  /deep/.el-dialog__wrapper {
    display: flex;
    align-items: center;
    .el-dialog {
      width: 288px;
      height: 360px;
      border-radius: 20px;
      margin: 0 auto !important;
      position: relative;
      .copy-link {
        font-size: 12px;
        color: #13154d;
        padding-left: 20px;
        margin-bottom: 10px;
      }
      .http {
        padding: 0 20px;
        box-sizing: border-box;
        .el-input__inner {
          height: 30px;
        }
        .el-input-group__append {
          background: #21e2a9;
          cursor: pointer;
          user-select: none;
          border: none;
          &:active {
            opacity: 0.6;
          }
        }
        .COPY {
          font-size: 12px;
          color: #fefefe;
          background: #21e2a9;
        }
      }
      .xiamian {
        display: flex;
        align-items: center;
        justify-content: space-around;
        .fenxian {
          img {
            cursor: pointer;
            margin: 15px 10px 0;
          }
        }
      }
      .tup {
        background: #21e2a9;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        p {
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          position: relative;
        }
        img {
          margin: 15px 0;
        }
      }
      .el-dialog__body {
        padding: 0;
        height: calc(360px - 30px);
        display: flex;
        flex-direction: column;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        .gunabi {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
      }
      .el-dialog__header {
        background: #21e2a9;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
    }
  }
}
</style>
